export const RESEND_INDICATION_TTL_TIMER = 30000;

export const EXPERIMENTS = {
  CREATE_PASSWORD_OFFER: 'specs.ident.createPasswordOffer',
  TWO_FA_AGGRESSIVE_OFFER: 'specs.ident.2faAggresiveOffer',
  SHOULD_SHOW_POST_LOGIN_OWNER_APP2_FA:
    'specs.ident.ShouldShowPostLoginOwnerApp2Fa',
  SHOW_ERROR_MESSAGE_FOR_FACEBOOK2_FA_USERS:
    'specs.ident.showErrorMessageForFacebook2FaUsers',
  SHOULD_SHOW_PREMIUM_USERS_2FA_ENFORCEMENT:
    'specs.ident.shouldShowPremiumUsers2FaEnforcement',
  SHOULD_STEPUP_EDIT_2FA: 'specs.ident.shouldStepupEdit2FA',
  SHOULD_ACTIVATE_NEW_COLORS_BRANDING:
    'specs.ident.ShouldActivateNewColorBrandingForAccountSettings',
};

export const BI_SCREEN_NAMES = {
  CHOOSE_DEVICE: 'choose_device',
  CONFIRM_DEVICE: 'confirm_device',
  CANCEL_SETUP: 'cancel_setup',
  FAILED_TO_CONFIRM_DEVICE: 'failed_to_confirm_device',
  LOGIN_REQUEST_DENIED: 'login_request_denied',
  CHOOSE_VERIFICATION_METHOD: 'choose_verification_method',
  APPROVE_DEVICE: 'approve device',
  ENFORCEMENT_FAILED_TO_CONFIRM_DEVICE: 'failed_to_confirm_device',
  ENFORCEMENT_LOGIN_REQUEST_WAS_DENIED: 'Login request was denied',
};

export const BI_BUTTON_NAMES = {
  NEXT: 'Next',
  MAYBE_LATER: 'Maybe Later',
  ACCOUNT_SETTINGS: 'Account Settings',
  BACK: 'Back',
  CANCEL: 'Cancel',
  RESEND_LOGIN_REQUEST: 'Resend Login Request',
  CANCEL_SETUP: 'Cancel Setup',
  X_BUTTON: 'X_button',
  SET_UP: 'Set Up',
  LEARN_MORE: 'Learn more',
};

export const BI_ORIGIN = {
  TWO_FA_ENFORCEMENT: '2fa_enforcement',
  TWO_FA_ENFORCEMENT_SECOND_METHOD: '2fa_enforcement_second_method',
};

export const CUSTOMER_CARE_BLOCKED_ACCOUNT_BOT_URL =
  'https://www.wix.com/support-chatbot?nodeId=aecd4efb-caf3-404d-b5f7-6f9a808f8853';

import { action, computed, observable, makeObservable } from 'mobx';
import { PremiumUsers2FaEnforcementStore } from '../premiumUsers2FaEnforcement';
import {
  app2FaSetupClickOnBack,
  app2FaSetupCodeErrorMessage,
  app2FaSetupSubmitCode,
  app2FaSetupClickOnCopyCode,
  app2FaSetupClickOnNextAfterScanningQrCode,
} from '@wix/bi-logger-dash/v2';
import { POST_LOGIN_ROUTES } from '../../../routes';

import { FormField } from '../utils/formField';
import { constraints } from '../validate';
import { MultiFactorIdentifier } from '../multiFactorAuth';
import { PostLoginStore } from '../../postLogin';
import { BI_ORIGIN } from '../../constants';
import { VerificationState } from '../../../components/PostLogin/VerifyButton';

export class AuthenticatorAppModalsStore {
  public codeField: FormField;
  public qrUrl: string;
  public sharedSecretKey: string;
  public verificationState: VerificationState = VerificationState.Unverified;
  public postLoginStore: PostLoginStore;

  constructor(
    public premiumUsers2FaEnforcementStore: PremiumUsers2FaEnforcementStore
  ) {
    const errorMsg = this.premiumUsers2FaEnforcementStore.postLoginStore.i18n.t(
      'multiFactorAuth.addAuthApp.codeModal.input.error.wrongLength'
    );
    this.codeField = new FormField('', [
      [constraints.maximum(6), errorMsg],
      [constraints.minimum(6), errorMsg],
    ]);
    makeObservable(this, {
      codeField: observable,
      qrUrl: observable,
      sharedSecretKey: observable,
      openQrModal: action,
      onQrModalConfirm: action,
      onBackToQrModalModalSubmit: action.bound,
      isSubmittable: computed,
      sharedSecretString: computed,
      verificationState: observable,
    });
  }

  public openQrModal({
    qrURL = this.qrUrl,
    sharedSecretKey = this.sharedSecretKey,
  }: {
    qrURL?: string;
    sharedSecretKey?: string;
  }) {
    this.qrUrl = qrURL;
    this.sharedSecretKey = sharedSecretKey;
    this.premiumUsers2FaEnforcementStore.postLoginStore.rootStore.navigationStore.navigate(
      POST_LOGIN_ROUTES.PREMIUM_USERS_2FA_AUTHENTICATOR_APP
    );
  }

  public onCloseCodeModal() {
    this.codeField.clear();
    this.verificationState = VerificationState.Verified;
    this.premiumUsers2FaEnforcementStore.goToOwnerAppOrDashboard(
      'close modal of add auth app'
    );
  }

  public onQrModalConfirm() {
    this.premiumUsers2FaEnforcementStore.sendBiEvent(
      app2FaSetupClickOnNextAfterScanningQrCode({
        mode: 'enable',
        origin: BI_ORIGIN.TWO_FA_ENFORCEMENT,
      })
    );
    this.premiumUsers2FaEnforcementStore.postLoginStore.rootStore.navigationStore.navigate(
      POST_LOGIN_ROUTES.PREMIUM_USERS_ENTER_AUTH_APP_CODE
    );
  }

  public onCopyQrCodeClicked() {
    this.premiumUsers2FaEnforcementStore.sendBiEvent(
      app2FaSetupClickOnCopyCode({
        mode: 'enable',
        origin: BI_ORIGIN.TWO_FA_ENFORCEMENT,
      })
    );
  }

  public async onCodeModalSubmit() {
    this.verificationState = VerificationState.Verifying;
    this.premiumUsers2FaEnforcementStore.sendBiEvent(
      app2FaSetupSubmitCode({
        mode: 'enable',
        origin: BI_ORIGIN.TWO_FA_ENFORCEMENT,
      })
    );
    try {
      await this.premiumUsers2FaEnforcementStore.postLoginStore.accountSettingsApi[
        MultiFactorIdentifier.AuthApp
      ].enable(
        this.premiumUsers2FaEnforcementStore.multiFactorAuth.password,
        this.sharedSecretKey,
        this.codeField.value
      );
      this.resetData();
      await this.premiumUsers2FaEnforcementStore.multiFactorAuth.handle2faSettingsUpdate();
      this.onCloseCodeModal();
    } catch (error: any) {
      this.verificationState = VerificationState.Unverified;
      const errorCode = error?.response?.data?.errorCode;
      const errorDescription =
        this.premiumUsers2FaEnforcementStore.postLoginStore.i18n.t(
          this.premiumUsers2FaEnforcementStore.postLoginStore.accountSettingsApi.errorHandling(
            errorCode
          )
        );
      this.premiumUsers2FaEnforcementStore.sendBiEvent(
        app2FaSetupCodeErrorMessage({
          mode: 'enable',
          error_reason: errorDescription,
          origin: BI_ORIGIN.TWO_FA_ENFORCEMENT,
        })
      );
      this.codeField.addError(errorDescription);
    }
  }

  public onBackToQrModalModalSubmit() {
    this.premiumUsers2FaEnforcementStore.sendBiEvent(
      app2FaSetupClickOnBack({
        mode: 'enable',
      })
    );
    this.openQrModal({});
  }

  get isSubmittable(): boolean {
    return this.codeField.isValid;
  }

  get sharedSecretString(): string {
    return this.sharedSecretKey?.match(/.{4}/g)?.join('-') ?? '';
  }
  resetData() {
    this.qrUrl = '';
    this.sharedSecretKey = '';
    this.codeField.clear();
  }
}

import { action, makeObservable, observable, runInAction } from 'mobx';
import {
  OFFERS_TO_ROUTES,
  POST_LOGIN_OFFERS,
  SEARCH_PARAMS,
} from '../utils/constants';
import { RootStore } from './root';
import {
  postLoginConfirmAccountDetails,
  postLoginRecoveryPhoneDetails,
  postLoginMfaDetails,
  postLoginChangeYourPasswordDetails,
} from '@wix/bi-logger-post-login/v2';
import { OwnerAppStore } from './ownerApp';
import { DeviceRegistry } from '../services/DeviceRegistry';
import { i18n as i18nType } from 'i18next';
import { ConfirmationModalStore } from './confirmationModal';
import { BI_BUTTON_NAMES, BI_SCREEN_NAMES, EXPERIMENTS } from './constants';
import { HttpClient } from '@wix/http-client';
import Experiments from '@wix/wix-experiments';
import { NotificationStore } from '../utils/notification';
import { AccountSettingsHttpApi } from '../services/accountSettingsApi';
import { Logger } from '@wix/web-bi-logger/dist/src/types';
import { PremiumUsers2FaEnforcementStore } from './premiumUsers2FaEnforcement/premiumUsers2FaEnforcement';
import { BiProfileServiceApi } from '../services/biProfileApi';
import { faEnforcementAllFlowsSrc5Evid237 } from '@wix/bi-logger-identity-data/v2';
import { POST_LOGIN_ROUTES } from '../routes';

export class PostLoginStore {
  public postLoginOffer: Offer;
  public targetUrl: string;
  public userDetails: UserDetailsDTO;
  public userPhone: UserPhoneDTO | null;
  public isLoaded: boolean = false;
  private readonly isCreatePasswordOfferEnabled: boolean = false;
  private readonly is2faAggressiveOfferEnabled: boolean = false;
  private readonly shouldShowPostLoginOwnerApp2FaEnabled: boolean = false;
  public shouldShowPremiumUsers2FaEnforcement: boolean = false;
  private urlParams: URLSearchParams = new URLSearchParams(
    window.location.search
  );
  private debugCase = this.urlParams.get('debugCase') ?? undefined;
  private forceEnforcement = this.urlParams.get('letsGoMets') ?? undefined;
  public ownerAppStore: OwnerAppStore;
  public premiumUsers2FaEnforcementStore: PremiumUsers2FaEnforcementStore;
  public deviceRegistry: DeviceRegistry;
  public i18n: i18nType;
  public confirmationModalStore: ConfirmationModalStore;
  public experiments: Experiments;
  public notificationStore: NotificationStore;
  public accountSettingsApi: AccountSettingsHttpApi;
  public biProfileServiceApi: BiProfileServiceApi;
  biLogger: Logger;
  public showEnforcement: boolean;
  public userGroups: string[];

  constructor(public rootStore: RootStore) {
    this.notificationStore = rootStore.notificationStore;
    this.experiments = rootStore.experiments;
    this.i18n = rootStore.i18n;
    this.confirmationModalStore = new ConfirmationModalStore(this.i18n);
    this.deviceRegistry = new DeviceRegistry(new HttpClient());
    this.accountSettingsApi = rootStore.accountSettingsApi;
    this.biProfileServiceApi = rootStore.biProfileServiceApi;
    this.biLogger = rootStore.biLogger;
    this.ownerAppStore = new OwnerAppStore(this);
    this.premiumUsers2FaEnforcementStore = new PremiumUsers2FaEnforcementStore(
      this
    );
    makeObservable(this, {
      postLoginOffer: observable,
      userDetails: observable,
      userPhone: observable,
      isLoaded: observable,
      fetchOffers: action.bound,
      fetchUserDetails: action.bound,
      fetchUserPhone: action.bound,
      showEnforcement: observable,
    });
    this.isCreatePasswordOfferEnabled = rootStore.experiments.enabled(
      EXPERIMENTS.CREATE_PASSWORD_OFFER
    );
    this.is2faAggressiveOfferEnabled = rootStore.experiments.enabled(
      EXPERIMENTS.TWO_FA_AGGRESSIVE_OFFER
    );
    this.shouldShowPostLoginOwnerApp2FaEnabled = rootStore.experiments.enabled(
      EXPERIMENTS.SHOULD_SHOW_POST_LOGIN_OWNER_APP2_FA
    );
    this.shouldShowPremiumUsers2FaEnforcement = rootStore.experiments.enabled(
      EXPERIMENTS.SHOULD_SHOW_PREMIUM_USERS_2FA_ENFORCEMENT
    );
  }

  public fetchResources = () => {
    return Promise.all([
      this.fetchOffers(),
      this.fetchUserDetails(),
      this.fetchUserPhone(),
    ]);
  };

  public async init(targetUrl?: string) {
    if (this.isLoaded) {
      return;
    }

    if (targetUrl) {
      this.targetUrl = targetUrl;
    } else {
      const encodedTargetUrl = sessionStorage.getItem(SEARCH_PARAMS.TARGET_URL);
      this.targetUrl = encodedTargetUrl
        ? decodeURIComponent(encodedTargetUrl)
        : 'https://manage.wix.com';
    }

    if (this.rootStore.displayStore.isMobile) {
      return this.rootStore.navigationStore.proceedToPostAuthUrl(
        this.targetUrl
      );
    }
    const postLoginOfferStr = sessionStorage.getItem(
      SEARCH_PARAMS.POST_LOGIN_OFFER
    );
    const userDetailsStr = sessionStorage.getItem(SEARCH_PARAMS.USER_DETAILS);
    try {
      if (postLoginOfferStr && userDetailsStr) {
        this.postLoginOffer = JSON.parse(postLoginOfferStr);
        const userDetails = JSON.parse(userDetailsStr);
        this.userDetails = userDetails.userDetails;
        this.userPhone = userDetails.userPhone;
        this.cleanSession();
      } else {
        await this.fetchResources();
      }
      if (this.debugCase) {
        this.postLoginOffer = {
          offerGuid: this.debugCase,
          offerName: this.debugCase,
          asset: {
            creative: {
              identifier: this.debugCase,
            },
          },
        };
      }

      if (this.shouldShowPremiumUsers2FaEnforcement) {
        await this.premiumUsers2FaEnforcementStore.init();
        this.showEnforcement = await this.getShowEnforcement();
        if (this.showEnforcement) {
          switch (window.location.pathname) {
            case `/signin${POST_LOGIN_ROUTES.PREMIUM_USERS_CONFIRM_EXIT_ENFORCEMENT}`:
              this.postLoginOffer = this.getSimulatedDeal(
                POST_LOGIN_OFFERS.CONFIRM_EXIT_ENFORCEMENT
              );
              break;
            default:
              this.postLoginOffer = this.getSimulatedDeal(
                POST_LOGIN_OFFERS.PREMIUM_USERS_2FA_ENFORCEMENT
              );
          }
        }
      }

      if (this.postLoginOffer) {
        await this.rootStore.managePasswordStore.fetchPasswordPolicy();
        const postLoginOfferId =
          this.debugCase ?? this.postLoginOffer.asset.creative.identifier;
        if (!this.offerEnabled(postLoginOfferId)) {
          return this.rootStore.navigationStore.proceedToPostAuthUrl(
            this.targetUrl
          );
        }
        this.rootStore.initPostLoginStores();
        await this.ownerAppStore.init();

        runInAction(() => {
          this.isLoaded = true;
        });
        return this.rootStore.navigationStore.navigate(
          OFFERS_TO_ROUTES[postLoginOfferId]
        );
      }
    } catch (error) {
      console.error(error);
    }
    this.rootStore.navigationStore.proceedToPostAuthUrl(this.targetUrl);
  }

  public async fetchUserDetails(): Promise<void> {
    this.userDetails = await this.rootStore.accountSettingsApi.getUserDetails();
  }

  public async fetchUserPhone() {
    this.userPhone = await this.rootStore.accountSettingsApi.getUserPhone();
  }

  public async fetchOffers() {
    const response: Offers = await this.rootStore.dealerApi.getOffers();
    if (response.offers.length > 0) {
      this.postLoginOffer = response.offers[0];
    }
  }

  public async fetchUserWorkspaces() {
    const response =
      await this.rootStore.accountSettingsApi.fetchUserWorkspaces();
    return response.accounts;
  }

  public offerSucceed = () => {
    this.rootStore.dealerApi.sendDealerMainCtaEvent(
      this.postLoginOffer.offerGuid,
      this.targetUrl
    );
    setTimeout(() => {
      this.proceedToPostAuthUrl('offer succeeded');
    }, 300);
  };

  public proceedToPostAuthUrl = (reason: string) => {
    console.log(`proceedToPostAuthUrl: reason: ${reason}`);
    this.rootStore.navigationStore.proceedToPostAuthUrl(this.targetUrl);
  };

  public showLater = () => {
    this.rootStore.dealerApi.sendDealerShowLaterEvent(
      this.postLoginOffer.offerGuid
    );
    this.rootStore.navigationStore.proceedToPostAuthUrl(this.targetUrl);
  };

  public cleanSession() {
    sessionStorage.removeItem('targetUrl');
    sessionStorage.removeItem('postLoginOffer');
    sessionStorage.removeItem('userDetails');
  }

  public async sendAccountSettingBiEvents() {
    switch (this.postLoginOffer.asset.creative.identifier) {
      case POST_LOGIN_OFFERS.CONFIRM_DETAILS:
        this.rootStore.biLogger.report(
          postLoginConfirmAccountDetails({
            button: 'Account settings',
            flowType: 'Confirm account details',
          })
        );
        break;
      case POST_LOGIN_OFFERS.CONFIRM_EMAIL:
        this.rootStore.biLogger.report(
          postLoginConfirmAccountDetails({
            button: 'Account settings',
            flowType: 'Confirm Email',
          })
        );
        break;
      case POST_LOGIN_OFFERS.MFA:
        this.rootStore.biLogger.report(
          postLoginMfaDetails({
            button: 'Account settings',
            mfa_type: 'Phone',
          })
        );
        break;
      case POST_LOGIN_OFFERS.RECOVERY_PHONE:
        this.rootStore.biLogger.report(
          postLoginRecoveryPhoneDetails({
            button: 'Account settings',
          })
        );
        break;
      case POST_LOGIN_OFFERS.UPDATE_PASSWORD:
        this.rootStore.biLogger.report(
          postLoginChangeYourPasswordDetails({
            button: 'Account settings',
          })
        );
        break;
      case POST_LOGIN_OFFERS.OWNER_APP_2FA:
        this.ownerAppStore.sendBiEvent(BI_BUTTON_NAMES.ACCOUNT_SETTINGS);
        break;
      case POST_LOGIN_OFFERS.PREMIUM_USERS_2FA_ENFORCEMENT:
        // noinspection SpellCheckingInspection
        this.premiumUsers2FaEnforcementStore.sendBiEvent(
          faEnforcementAllFlowsSrc5Evid237({
            screenName: BI_SCREEN_NAMES.CHOOSE_VERIFICATION_METHOD,
            button_name: BI_BUTTON_NAMES.LEARN_MORE,
            isHover: false,
            hasOwnerApp:
              this.premiumUsers2FaEnforcementStore.userHasOwnerAppInstalled,
          })
        );
        break;
    }
  }

  private getSimulatedDeal(identifier: string) {
    return {
      offerGuid: '',
      offerName: '',
      asset: {
        creative: {
          identifier,
        },
      },
    };
  }

  async getShowEnforcement(): Promise<boolean> {
    await this.premiumUsers2FaEnforcementStore.fetchTwoFASettings();
    if (this.forceEnforcement) {
      return true;
    }
    this.userGroups = (await this.biProfileServiceApi.getUserGroups()).map(
      (profileValue) => profileValue.aString || ''
    );
    if (
      !this.userGroups.includes('active_billing_users') ||
      !this.userGroups.includes('users_with_verified_email') ||
      !this.userGroups.includes('user_has_password')
    ) {
      return false;
    }
    const excludedProfile = await this.biProfileServiceApi.getUserProfile(
      'users_excluded_from_2fa_enforcement'
    );
    if (excludedProfile) {
      return false;
    }
    return this.premiumUsers2FaEnforcementStore.multiFactorAuth
      .hasNoMethodsEnabled;
  }

  private offerEnabled(offerId: string) {
    switch (offerId) {
      case POST_LOGIN_OFFERS.CREATE_PASSWORD:
        return this.isCreatePasswordOfferEnabled;
      case POST_LOGIN_OFFERS.MFA_AGGRESSIVE:
        return this.is2faAggressiveOfferEnabled;
      case POST_LOGIN_OFFERS.OWNER_APP_2FA:
        return this.shouldShowPostLoginOwnerApp2FaEnabled;
      default:
        return true;
    }
  }
}

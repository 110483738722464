import React, { useContext } from 'react';
import { CustomModalLayout, Box, Text, Heading } from '@wix/design-system';
import { observer } from 'mobx-react';
import s from './modals.scss';
import { AppContextProvider } from '../../AppLoader';
import { ReactComponent as ConfirmExitIllustration } from '../../../assets/images/confirmExit.svg';
import { POST_LOGIN_ROUTES } from '../../../routes';
import {
  faEnforcementAllFlowsSrc5Evid237,
  faEnforcementUserExcludedSrc18Evid2,
} from '@wix/bi-logger-identity-data/v2';
import { useTranslation } from 'react-i18next';
import { MainHeader } from '../../MainHeader';

export const ConfirmExitEnforcement: React.FC = observer(() => {
  const {
    rootStore: {
      postLoginStore: { premiumUsers2FaEnforcementStore: store },
    },
  } = useContext(AppContextProvider);
  const { t } = useTranslation();

  return (
    <Box direction="vertical" width="100%" align="center">
      <MainHeader showBackButton={false} />
      <CustomModalLayout
        height="222px"
        width="600px"
        secondaryButtonText={t('confirm.exit.enforcement.primary.button.text')}
        secondaryButtonOnClick={() => {
          store.sendBiEvent(
            faEnforcementAllFlowsSrc5Evid237({
              screenName: 'exclude option',
              button_name: 'Add verification method',
            })
          );
          store.navigationStore.navigate(
            POST_LOGIN_ROUTES.PREMIUM_USERS_2FA_ENFORCEMENT
          );
        }}
        primaryButtonText={t('confirm.exit.enforcement.secondary.button.text')}
        primaryButtonOnClick={() => {
          store.sendBiEvent(
            faEnforcementAllFlowsSrc5Evid237({
              screenName: 'exclude option',
              button_name: 'Continue to site',
            })
          );
          store.sendBiEvent(
            faEnforcementUserExcludedSrc18Evid2({
              is_success: true,
              user_id:
                store.postLoginStore.rootStore.postLoginStore.userDetails.guid,
            })
          );
          store.postLoginStore.proceedToPostAuthUrl(
            'confirmed exit from enforcement'
          );
        }}
        removeContentPadding
        className={s.confirmExitConfirmation}
      >
        <Box
          direction="horizontal"
          padding="24px 24px 12px 24px"
          maxHeight="117px"
        >
          <Box marginRight={4}>
            <ConfirmExitIllustration />
          </Box>
          <Box verticalAlign="middle" direction="vertical" gap={2}>
            <Heading size="medium">
              {t('confirm.exit.enforcement.title')}
            </Heading>
            <Text>{t('confirm.exit.enforcement.text')}</Text>
          </Box>
        </Box>
      </CustomModalLayout>
    </Box>
  );
});
